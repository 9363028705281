import React from 'react';
import Icon from '../icon';

import angle from '../svg/angle.small.sprite.svg';

const IconAngleSmall = (props) => {
	return <Icon {...props} sprite={angle} isCssModule />;
};

IconAngleSmall.propTypes = Icon.propTypes;
IconAngleSmall.defaultProps = Icon.defaultProps;

export default IconAngleSmall;
