/* eslint-disable react/prop-types */
import React from 'react';
import cn from 'classnames';
import Icon from '../component';
import sprite from './expand-arrow.sprite.svg';

export const IconExpandArrow = (props) => {
	const { className } = props;
	return (
		<Icon
			sprite={sprite}
			{...props}
			className={cn('spotlyst-icon icon arrow-x-small', className)}
		/>
	);
};
