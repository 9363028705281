import Icon from './icon';
import * as Icons from './component';

export * from './sprites/star.sprite';
export * from './sprites/expand-arrow.sprite';
export * from './sprites/heart.sprite';
export * from './sprites/flash.sprite';
export * from './sprites/subway-moscow.sprite';

export default Icon;

export const CloseSmallIcon = Icons.CloseSmall;
export const SearchIcon = Icons.Search;
export const ArrowMediumLIcon = Icons.ArrowMediumL;
export const ArrowMediumRIcon = Icons.ArrowMediumR;
export const PointerIcon = Icons.Pointer;
export const StarSmallIcon = Icons.StarSmall;
export const HeartGlowIcon = Icons.HeartGlow;
export const ArrowXSmallIcon = Icons.ArrowXSmall;
export const ArrowSmallLIcon = Icons.ArrowSmallL;
export const ArrowSmallRIcon = Icons.ArrowSmallR;
export const EllipsisIcon = Icons.Ellipsis;
export const ArrowLargeLIcon = Icons.ArrowLargeL;
export const ArrowLargeRIcon = Icons.ArrowLargeR;
export const LogoIcon = Icons.Logo;
export const LogoSmallIcon = Icons.LogoSmall;
export const HeartHollowIcon = Icons.HeartHollow;
export const CloseIcon = Icons.Close;
export const LogoResponsiveIcon = Icons.LogoResponsive;
export const PlusIcon = Icons.Plus;
export const MinusIcon = Icons.Minus;
export const StripesIcon = Icons.Stripes;
export const GeoIcon = Icons.Geo;
export const ChainIcon = Icons.Chain;
export const CubeIcon = Icons.Cube;
export const CheckIcon = Icons.Check;
export const FilterIcon = Icons.Filter;
export const LogoHomeIcon = Icons.LogoHome;
export const LoadingIcon = Icons.Loading;
export const LogoResponsiveFullIcon = Icons.LogoResponsiveFull;
export const Geo2Icon = Icons.Geo2;
export const Cube2Icon = Icons.Cube2;
