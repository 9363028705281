import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from './icon';

import ArrowMediumSprite from './sprites/arrow-medium.sprite.svg';
import CloseSmallSprite from './sprites/close-small.sprite.svg';
import SearchSprite from './sprites/search.sprite.svg';
import PointerSprite from './sprites/pointer.sprite.svg';
import StarSprite from './sprites/star.sprite.svg';
import ArrowXSmallSprite from './sprites/arrow-x-small.sprite.svg';
import ArrowSmallSprite from './sprites/arrow-small.sprite.svg';
import EllipsisSprite from './sprites/ellipsis.sprite.svg';
import ArrowLargeSprite from './sprites/arrow-large.sprite.svg';
import LogoSmallSprite from './sprites/logo-small.sprite.svg';
import LogoBigSprite from './sprites/logo-big.sprite.svg';
import LogoSprite from './sprites/logo.sprite.svg';
import HeartHollowSprite from './sprites/heart-hollow.sprite.svg';
import CloseSprite from './sprites/close.sprite.svg';
import PlusSprite from './sprites/plus.sprite.svg';
import MinusSprite from './sprites/minus.sprite.svg';
import StripesSprite from './sprites/stripes.sprite.svg';
import GeoSprite from './sprites/geo.sprite.svg';
import ChainSprite from './sprites/chain.sprite.svg';
import CubeSprite from './sprites/cube.sprite.svg';
import CheckSprite from './sprites/check.sprite.svg';
import FilterSprite from './sprites/filter.sprite.svg';
import LoadingSprite from './sprites/loading.sprite.svg';
import HeartFullSprite from './sprites/heart-full.sprite.svg';
import Geo2Sprite from './sprites/geo2.sprite.svg';
import Cube2Sprite from './sprites/cube2.sprite.svg';

import css from './style.module.scss';

export default Icon;

export const CloseSmall = (props) => (
	<Icon sprite={CloseSmallSprite} width="10" height="10" {...props} />
);

export const Search = (props) => (
	<Icon sprite={SearchSprite} width="16" height="16" {...props} />
);

export const ArrowMediumL = (props) => (
	<Icon
		sprite={ArrowMediumSprite}
		width="10"
		height="18"
		mods={['left']}
		{...props}
	/>
);

export const ArrowMediumR = (props) => (
	<Icon
		sprite={ArrowMediumSprite}
		width="10"
		height="18"
		mods={['right']}
		{...props}
	/>
);

export const Pointer = (props) => (
	<Icon sprite={PointerSprite} width="18" height="12" {...props} />
);

export const StarSmall = (props) => (
	<Icon sprite={StarSprite} width="10" height="10" {...props} />
);

export const HeartGlow = (props) => (
	<Icon
		sprite={HeartFullSprite}
		width="24"
		height="22"
		className="heart-glow"
		{...props}
	/>
);

export const ArrowXSmall = (props) => (
	<Icon sprite={ArrowXSmallSprite} width="10" height="10" {...props} />
);

export const ArrowSmallR = (props) => (
	<Icon
		sprite={ArrowSmallSprite}
		width="14"
		height="14"
		mods={['right']}
		{...props}
	/>
);

export const ArrowSmallL = (props) => (
	<Icon
		sprite={ArrowSmallSprite}
		width="14"
		height="14"
		mods={['left']}
		{...props}
	/>
);

export const Ellipsis = (props) => (
	<Icon sprite={EllipsisSprite} width="17" height="4" {...props} />
);

export const ArrowLargeR = (props) => (
	<Icon
		sprite={ArrowLargeSprite}
		width="11"
		height="20"
		mods={['right']}
		{...props}
	/>
);

export const ArrowLargeL = (props) => (
	<Icon
		sprite={ArrowLargeSprite}
		width="11"
		height="20"
		mods={['left']}
		{...props}
	/>
);

export const Logo = (props) => (
	<Icon sprite={LogoSprite} width="28" height="32" {...props} />
);

export const LogoSmall = (props) => (
	<Icon sprite={LogoSmallSprite} height="18" {...props} />
);

export const LogoBig = (props) => (
	<Icon sprite={LogoBigSprite} height="18" {...props} />
);

export const HeartHollow = (props) => (
	<Icon sprite={HeartHollowSprite} width="18" height="16" {...props} />
);

export const Close = (props) => (
	<Icon sprite={CloseSprite} width="16" height="16" {...props} />
);

export const LogoResponsive = (props) => {
	const processedProps = { ...props };
	delete processedProps.width;
	return (
		<div className="icon logo-responsive">
			<LogoSmall height="18" {...processedProps} />
			<Logo {...processedProps} />
		</div>
	);
};
LogoResponsive.propTypes = {
	width: PropTypes.number,
};
LogoResponsive.defaultProps = {
	width: 320,
};

export const LogoResponsiveFull = (props) => {
	return (
		<div className={cn(css.icon, css.icon_adapt_responsive)}>
			<LogoSmall {...props} className={css.small} height="18" />
			<LogoBig
				{...props}
				className={cn(css.big, css.white)}
				width="106"
				height="32"
			/>
		</div>
	);
};

export const Plus = (props) => (
	<Icon sprite={PlusSprite} width="12" height="12" {...props} />
);

export const Minus = (props) => (
	<Icon sprite={MinusSprite} width="12" height="2" {...props} />
);

export const Stripes = (props) => (
	<Icon sprite={StripesSprite} width="10" height="8" {...props} />
);

export const Geo = (props) => (
	<Icon sprite={GeoSprite} width="13" height="16" {...props} />
);

export const Chain = (props) => (
	<Icon sprite={ChainSprite} width="16" height="17" {...props} />
);

export const Cube = (props) => (
	<Icon sprite={CubeSprite} width="15" height="17" {...props} />
);

export const Check = (props) => (
	<Icon
		sprite={CheckSprite}
		width="20"
		height="20"
		viewBox="0 0 16 12"
		{...props}
	/>
);

export const Filter = (props) => (
	<Icon sprite={FilterSprite} width="16" height="16" {...props} />
);

export const LogoHome = (props) => {
	return (
		<div className="icon logo-home">
			<LogoSmall {...props} height={18} mods="mobile" />
			<LogoSmall {...props} height={32} mods="desktop" />
		</div>
	);
};
LogoResponsive.propTypes = {
	width: PropTypes.number,
};
LogoResponsive.defaultProps = {
	width: 320,
};

export const Loading = (props) => (
	<Icon sprite={LoadingSprite} width="24" height="24" {...props} />
);

export const Geo2 = (props) => (
	<Icon sprite={Geo2Sprite} width="24" height="25" {...props} />
);

export const Cube2 = (props) => (
	<Icon sprite={Cube2Sprite} width="24" height="25" {...props} />
);
